<template>
  <div class="form">
    <form-header :title="$t('editProject')" :cancel-url="urls.cancel">
      <button-gcr
        data-test-id="btn-submit-header"
        btn-class="btn-primary btn-sm form__header__btn"
        label="saveProject"
        :disabled="isReadOnly"
        :loading="submitting"
        @btnclick="validateAnd(updateProject)"
      />
    </form-header>

    <form ref="form">
      <new-project-form :project="project" disable-project-type-edition />
    </form>

    <form-footer :cancel-url="urls.cancel">
      <button-gcr
        data-test-id="btn-submit-footer"
        btn-class="btn-primary btn-sm form__footer__btn"
        label="saveProject"
        :disabled="isReadOnly"
        :loading="submitting"
        @btnclick="validateAnd(updateProject)"
      />
    </form-footer>
  </div>
</template>

<script>
import FormHeader from '@/components/molecules/FormHeader'
import FormFooter from '@/components/molecules/FormFooter'
import NewProjectForm from '@/components/organism/NewProjectForm'
import ButtonGcr from '@/components/atoms/Button'
import { Consts, Urls } from '@/_helpers'
import alerts from '@/_helpers/alerts'
import updateProject from '@/graphql/accreditations/projects/updateProject.graphql'
import getProject from '@/graphql/accreditations/projects/getProject.graphql'
import { mapGetters } from 'vuex'
import formDirty from '@/components/mixins/form-dirty.js'
import Project from '@/_models/Project'

export default {
  components: { FormFooter, FormHeader, NewProjectForm, ButtonGcr },
  mixins: [formDirty],
  props: {
    projectId: String
  },
  data() {
    return {
      submitting: false,
      project: Project.empty(),
      urls: {
        cancel: { name: Urls.PROJECTS }
      }
    }
  },
  computed: {
    ...mapGetters({
      accreditationId: Consts.GETTER_ACCREDITATION_CURRENT_ID,
      isReadOnly: Consts.GETTER_USER_ISSAC
    })
  },
  apollo: {
    getProject: {
      query: getProject,
      variables() {
        return {
          accredid: this.accreditationId,
          projectid: this.projectId
        }
      },
      update({accreditation: { project }}) {
        this.project.name = project.name
        this.project.city = project.city
        this.project.type = project.type
        this.project.plannedUnitCount = project.plannedUnitCount
        this.project.lotNumber = project.lotNumber
        this.project.totalConstructionCost = project.totalConstructionCost
        this.project.fundingAmount = project.fundingAmount
        this.project.fundingSource = project.fundingSource
      }
    }
  },
  methods: {
    async validateAnd(action) {
      try {
        if (!this.validateForm()) return

        this.submitting = true
        await action()
      } finally {
        this.submitting = false
      }
    },
    async updateProject() {
      const projectId = await this.$apollo
        .mutate({
          mutation: updateProject,
          variables: {
            details: {
              accreditationId: this.accreditationId,
              projectId: this.projectId,
              name: this.project.name,
              city: this.project.city,
              type: this.project.type,
              plannedUnitCount: this.project.plannedUnitCount,
              lotNumber: this.project.lotNumber,
              totalConstructionCost: this.project.totalConstructionCost,
              fundingAmount: this.project.fundingAmount,
              fundingSource: this.project.fundingSource,
            }
          }
        })
        .then(response => response.data.updateProject.project.id)
      this.close(projectId)
    },
    close(id) {
      this.dirty = false
      alerts.showInfo('projectEditSuccess')
      this.$router.push({
        name: Urls.PROJECT_DETAILS,
        query: { id }
      })
    },
    validateForm() {
      this.$refs.form.classList.add('was-validated')
      return this.$refs.form.checkValidity()
    }
  }
}
</script>
